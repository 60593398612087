import styled from "styled-components";

export const LegalParagraph = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
`;

export const OuterContainer = styled.div`
    padding: 100px;
    
    @media (max-width: 768px) {
        padding: 20px
  }
  
`;
