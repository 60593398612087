import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer/footer.component";
import { LegalParagraph, OuterContainer } from "./legalStyles";
const TermsAndConditions = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <OuterContainer>
        <button
          style={{
            background: "none",
            color: "inherit",
            font: "inherit",
            cursor: "pointer",
            outline: "inherit",
            paddingInline: 20,
            paddingTop: 10,
            paddingBottom: 10,
            marginBottom: 50,
            border: "1px solid black",
            fontSize: 16,
            fontWeight: "bold",
          }}
          onClick={() => navigate(-1)}
        >
          Zurück zum Checkin
        </button>
        <LegalParagraph>
          <h1 style={{marginBottom: 10, wordBreak: "break-word"}}>ALLGEMEINE GESCHÄFTSBEDINGUNGEN FÜR DIE HOTELLERIE 2006</h1>
          <h3 style={{marginBottom: 10}}>(AGBH 2006)
          </h3>
          <p style={{marginBottom: 50}}>
            <em>Fassung vom 15.11.2006</em>
          </p>
          <h2>Inhaltsübersicht</h2>
        </LegalParagraph>

        <LegalParagraph>
          <p style={{lineHeight: 2}}>
            §01 Geltungsbereich
            <br />
            §02 Begriffsdefinitionen
            <br />
            §03 Vertragsabschluss – Anzahlung
            <br />
            §04 Beginn und Ende der Beherbergung
            <br />
            §05 Rücktritt vom Beherbergungsvertrag – Stornogebühr
            <br />
            §06 Beistellung einer Ersatzunterkunft
            <br />
            §07 Rechte des Vertragspartners
            <br />
            §08 Pflichten des Vertragspartners
            <br />
            §09 Rechte des Beherbergers
            <br />
            §10 Pflichten des Beherbergers
            <br />
            §11 Haftung des Beherbergers für Schäden an eingebrachten Sachen
            <br />
            §12 Haftungsbeschränkungen
            <br />
            §13 Tierhaltung
            <br />
            §14 Verlängerung der Beherbergung
            <br />
            §15 Beendigung des Beherbergungsvertrages – Vorzeitige Auflösung
            <br />
            §16 Erkrankung oder Tod des Gastes im Beherbergungsvertrag
            <br />
            §17 Erfüllungsort, Gerichtsstand und Rechtswahl
            <br />
            §18 Sonstiges
          </p>
        </LegalParagraph>

        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>§ 1 Geltungsbereich</h3>
          <p style={{marginBottom: 10}}>
            1.1 Diese Allgemeinen Geschäftsbedingungen für die Hotellerie (im
            Folgenden „AGBH 2006“) ersetzen die bisherigen ÖHVB in der Fassung
            vom 23. September 1981.
          </p>
          <p style={{marginBottom: 10}}>
            1.2 Die AGBH 2006 schließen Sondervereinbarungen nicht aus. Die AGBH
            2006 sind gegenüber im Einzelnen getroffenen Vereinbarungen
            subsidiär.
          </p>
        </LegalParagraph>

        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>§ 2 Begriffsdefinitionen</h3>
          <p style={{marginBottom: 10}}>
            2.1 Begriffsdefinitionen:
            <br />
            „Beherberger“: Ist eine natürliche oder juristische Person, die
            Gäste gegen Entgelt beherbergt.
          </p>
          <p style={{marginBottom: 10}}>
            „Gast“: Ist eine natürliche Person, die Beherbergung in Anspruch
            nimmt. Der Gast ist in der Regel zugleich Vertragspartner. Als
            Gast gelten auch jene Personen, die mit dem Vertragspartner an-
            reisen (zB Familienmitglieder, Freunde etc).
          </p>
          <p style={{marginBottom: 10}}>
            „Vertragspartner“: Ist eine natürliche oder juristische Person des
            Inoder Auslandes, die als Gast oder für einen Gast einen Beher-
            bergungsvertrag abschließt.
          </p>
          <p style={{marginBottom: 10}}>
            „Konsument“ und „Unternehmer“: Die Begriffe sind im Sinne des Kon-
            sumentenschutzgesetzes 1979 idgF zu verstehen.
          </p>
          <p style={{marginBottom: 10}}>
            „Beherbergungsvertrag“: Ist der zwischen dem Beherberger und dem V
            ertragspartner abgeschlossene Vertrag, dessen Inhalt in der Folge
            näher geregelt wird.
          </p>
        </LegalParagraph>

        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>§ 3 Vertragsabschluss – Anzahlung</h3>
          <p style={{marginBottom: 10}}>
            3.1 Der Beherbergungsvertrag kommt durch die Annahme der Bestellung
            des Vertragspartners durch den Beherberger zustande. Elektronische
            Erklärungen gelten als zugegangen, wenn die Partei, für die sie
            bestimmt sind, diese unter gewöhnlichen Umständen abrufen kann,
            und der Zugang zu den bekannt gegebenen Geschäftszeiten des
            Beherbergers erfolgt.
          </p>
          <p style={{marginBottom: 10}}>
            3.2 Der Beherberger ist berechtigt, den Beherbergungsvertrag unter
            der Bedingung abzuschließen, dass der Vertragspartner eine Anzahlung
            leistet. In diesem Fall ist der Beherberger verpflichtet, vor der
            Annahme der schriftlichen oder mündlichen Bestellung des
            Vertragspartners, den Vertragspartner auf die geforderte Anzahlung
            hinzuweisen. Erklärt sich der Vertragspartner mit der Anzahlung
            (schriftlich oder mündlich) einverstanden, kommt der
            Beherbergungsvertrag mit Zugang der Einverständniserklärung über die
            Bezahlung der Anzahlung des Vertragspartners beim Beherberger
            zustande.
          </p>
          <p style={{marginBottom: 10}}>
            3.3 Der Vertragspartner ist verpflichtet, die Anzahlung spätestens 7
            Tage (einlangend) vor der Beherbergung zu bezahlen. Die Kosten für
            die Geldtransaktion (zB Überweisungsspesen) trägt der
            Vertragspartner. Für Kreditund Debitkarten gelten die jeweiligen
            Bedingungen der Kartenunternehmen.
          </p>
          <p style={{marginBottom: 10}}>
            3.4 Die Anzahlung ist eine Teilzahlung auf das vereinbarte Entgelt.
          </p>
        </LegalParagraph>

        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>§ 4 Beginn und Ende der Beherbergung</h3>
          <p style={{marginBottom: 10}}>
            4.1 Der Vertragspartner hat das Recht, so der Beherberger keine
            andere Bezugszeit anbietet, die gemieteten Räume ab 15.00 Uhr des
            vereinbarten Tages („Ankunftstag“) zu beziehen.
          </p>
          <p style={{marginBottom: 10}}>
            4.2 Wird ein Zimmer erstmalig vor 6.00 Uhr Früh in Anspruch
            genommen, so zählt die vorhergegangene Nacht als erste Übernachtung.
          </p>
          <p style={{marginBottom: 10}}>
            4.3 Die gemieteten Räume sind durch den Vertragspartner am Tag der
            Abreise bis 10.00 Uhr freizumachen. Der Beherberger ist
            berechtigt, einen weiteren Tag in Rechnung zu stellen, wenn die
            gemieteten Räume nicht fristgerecht freigemacht sind.
          </p>
        </LegalParagraph>

        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>§ 5 Rücktritt vom Beherbergungsvertrag – Stornogebühr</h3>
          <p style={{marginBottom: 10}}>
            <strong>Rücktritt durch den Beherberger</strong>
            <br />
            5.1 Sieht der Beherbergungsvertrag eine Anzahlung vor und wurde die
            Anzahlung vom Vertragspartner nicht fristgerecht geleistet, kann der
            Beherberger ohne Nachfrist vom Beherbergungsvertrag zurücktreten.
          </p>
          <p style={{marginBottom: 10}}>
            5.2 Falls der Gast bis 18.00 Uhr des vereinbarten Ankunftstages
            nicht erscheint, besteht keine Beherbergungspflicht, es sei denn,
            dass ein späterer Ankunftszeitpunkt vereinbart wurde.
          </p>
        </LegalParagraph>

        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>§ 6 Beistellung einer Ersatzunterkunft</h3>
          <p style={{marginBottom: 10}}>
            6.1 Der Beherberger kann dem Vertragspartner bzw den Gästen eine
            adäquate Ersatzunterkunft (gleicher Qualität) zur Verfügung
            stellen, wenn dies dem Vertragspartner zumutbar ist, besonders
            wenn die Abweichung geringfügig und sachlich gerechtfertigt ist.
          </p>
          <p style={{marginBottom: 10}}>
            6.2 Eine sachliche Rechtfertigung ist beispielsweise dann gegeben,
            wenn der Raum (die Räume) unbenutzbar geworden ist (sind), bereits
            einquartierte Gäste ihren Aufenthalt verlängern, eine Überbuchung
            vorliegt oder sonstige wichtige betriebliche Maßnahmen diesen
            Schritt bedingen.
          </p>
          <p style={{marginBottom: 10}}>
            6.3 Allfällige Mehraufwendungen für das Ersatzquartier gehen auf
            Kosten des Beherbergers.
          </p>
        </LegalParagraph>

        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>§ 7 Rechte des Vertragspartners</h3>
          <p style={{marginBottom: 10}}>
            7.1 Durch den Abschluss eines Beherbergungsvertrages erwirbt der
            Vertragspartner das Recht auf den üblichen Gebrauch der gemieteten
            Räume, der Einrichtungen des Beherbergungsbetriebes, die üblicher
            Weise und ohne besondere Bedingungen den Gästen zur Benützung
            zugänglich sind, und auf die übliche Bedienung. Der Vertragspartner
            hat seine Rechte gemäß allfälligen Hotelund/oder Gäste-
            richtlinien (Hausordnung) auszuüben.
          </p>
        </LegalParagraph>

        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>§ 8 Pflichten des Vertragspartners</h3>
          <p style={{marginBottom: 10}}>
            8.1 Der Vertragspartner ist verpflichtet, spätestens zum Zeitpunkt
            der Abreise das vereinbarte Entgelt zuzüglich etwaiger
            Mehrbeträge, die auf Grund gesonderter Leistungsinanspruchnahme
            durch ihn und/oder die ihn begleitenden Gästen entstanden sind
            zuzüglich gesetzlicher Umsatzsteuer zu bezahlen.
          </p>
          <p style={{marginBottom: 10}}>
            8.2 Der Beherberger ist nicht verpflichtet, Fremdwährungen zu
            akzeptieren. Akzeptiert der Beherberger Fremdwährungen, werden
            diese nach Tunlichkeit zum Tageskurs in Zahlung genommen. Sollte
            der Beherberger Fremdwährungen oder bargeldlose Zahlungsmittel
            akzeptieren, so trägt der Vertragspartner alle damit
            zusammenhängenden Kosten, etwa Erkundigungen bei
            Kreditkartenunternehmungen, Telegramme, usw.
          </p>
          <p style={{marginBottom: 10}}>
            8.3 Der Vertragspartner haftet dem Beherberger gegenüber für jeden
            Schaden, den er oder der Gast oder sonstige Personen, die mit Wissen
            oder Willen des Vertragspartners Leistungen des Beherbergers
            entgegennehmen, verursachen.
          </p>
        </LegalParagraph>

        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>§ 9 Rechte des Beherbergers</h3>
          <p style={{marginBottom: 10}}>
            9.1 Verweigert der Vertragspartner die Bezahlung des bedungenen
            Entgelts oder ist er damit im Rückstand, so steht dem Beherberger
            das gesetzliche Zurückbehaltungsrecht gemäß § 970c ABGB sowie das
            gesetzliche Pfandrecht gem § 1101 ABGB an den vom Vertragspartner
            bzw dem vom Gast eingebrachten Sachen zu. Dieses Zurückbehaltungs-
            oder Pfandrecht steht dem Beherberger weiters zur Sicherung seiner
            Forderung aus dem Beherbergungsvertrag, insbesondere für Ver-
            pflegung, sonstiger Auslagen, die für den Vertragspartner gemacht
            wurden und für allfällige Ersatzansprüche jeglicher Art zu.
          </p>
          <p style={{marginBottom: 10}}>
            9.2 Wird das Service im Zimmer des Vertragspartners oder zu
            außergewöhnlichen Tageszeiten (nach 20,00 Uhr und vor 6,00 Uhr)
            verlangt, so ist der Beherberger berechtigt, dafür ein Sonderentgelt
            zu verlangen. Dieses Sonderentgelt ist jedoch auf der
            Zimmerpreistafel auszuzeichnen. Der Beherberger kann diese
            Leistungen aus betrieblichen Gründen auch ablehnen.
          </p>
          <p style={{marginBottom: 10}}>
            9.3 Dem Beherberger steht das Recht auf jederzeitige Abrechnung
            bzw Zwischenabrechung seiner Leistung zu.
          </p>
        </LegalParagraph>

        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>§ 10 Pflichten des Beherbergers</h3>
          <p style={{marginBottom: 10}}>
            10.1 Der Beherberger ist verpflichtet, die vereinbarten Leistungen
            in einem seinem Standard entsprechenden Umfang zu erbringen.
          </p>
          <p style={{marginBottom: 10}}>
            10.2 Auszeichnungspflichtige Sonderleistungen des Beherbergers, die
            nicht im Beherbergungsentgelt inbegriffen sind, sind beispielhaft:
            <br />
            a) Sonderleistungen der Beherbergung, die gesondert in Rechnung
            gestellt werden können, wie die Bereitstellung von Salons, Sauna,
            Hallenbad, Schwimmbad, Solarium, Garagierung usw;
            <br />
            b) für die Bereitstellung von Zusatzbzw Kinderbetten wird ein
            ermäßigter Preis berechnet.
          </p>
        </LegalParagraph>

        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>
            § 11 Haftung des Beherbergers für Schäden an eingebrachten Sachen
          </h3>
          <p style={{marginBottom: 10}}>
            11.1 Der Beherberger haftet gemäß §§ 970 ff ABGB für die vom
            Vertragspartner eingebrachten Sachen. Die Haftung des Beherbergers
            ist nur dann gegeben, wenn die Sachen dem Beherberger oder den vom
            Beherberger befugten Leuten übergeben oder an einen von diesen
            angewiesenen oder hiezu bestimmten Ort gebracht worden sind. Sofern
            dem Beherberger der Beweis nicht gelingt, haftet der Beherberger
            für sein eigenes Verschulden oder das Verschulden seiner Leute sowie
            der ausund eingehende Personen. Der Beherberger haftet gemäß § 970
            Abs 1 ABGB höchstens bis zu dem im Bundesgesetz vom 16. November
            1921 über die Haftung der Gastwirte und anderer Unternehmer in der
            jeweils geltenden Fassung festgesetzten Betrag. Kommt der
            Vertragspartner oder der Gast der Aufforderung des Beherbergers,
            seine Sachen an einem besonderen Aufbewahrungsort zu hinterlegen
            nicht unverzüglich nach, ist der Beherberger aus jeglicher Haftung
            befreit. Die Höhe einer allfälligen Haftung des Beherbergers ist
            maximal mit der Haftpflichtversicherungssumme des jeweiligen
            Beherbergers begrenzt. Ein Verschulden des Vertragspartners oder
            Gastes ist zu berücksichtigen.
          </p>
          <p style={{marginBottom: 10}}>
            11.2 Die Haftung des Beherbergers ist für leichte Fahrlässigkeit
            ausgeschlossen. Ist der Vertragspartner ein Unternehmer wird die
            Haftung auch für grobe Fahrlässigkeit ausgeschlossen. In diesem
            Fall trägt der Vertragspartner die Beweislast für das Vorliegen des
            Verschuldens. Folgeschäden oder indirekte Schäden sowie entgangene
            Gewinne werden keinesfalls ersetzt.
          </p>
          <p style={{marginBottom: 10}}>
            11.3 Für Kostbarkeiten, Geld und Wertpapiere haftet der Beherberger
            nur bis zum Betrag von derzeit € 550,–. Der Beherberger haftet für
            einen darüber hinausgehenden Schaden nur in dem Fall, dass er
            diese Sachen in Kenntnis ihrer Beschaffenheit zur Aufbewahrung
            übernommen hat oder in dem Fall, dass der Schaden von ihm selbst
            oder einen seiner Leute verschuldet wurde. Die Haftungsbeschränkung
            gemäß 12.1 und 12.2 gilt sinngemäß.
          </p>
          <p style={{marginBottom: 10}}>
            11.4 Die Verwahrung von Kostbarkeiten, Geld und Wertpapieren kann
            der Beherberger ablehnen, wenn es sich um wesentlich wertvollere
            Gegenstände handelt, als Gäste des betreffenden
            Beherbergungsbetriebes gewöhnlich in Verwahrung geben.
          </p>
          <p style={{marginBottom: 10}}>
            11.5 In jedem Fall der übernommenen Aufbewahrung ist die Haftung
            ausgeschlossen, wenn der Vertragspartner und/oder Gast den
            eingetretenen Schaden ab Kenntnis nicht unverzüglich dem Beherberger
            anzeigt. Überdies sind diese Ansprüche innerhalb von drei Jahren
            ab Kenntnis oder möglicher Kenntnis durch den Vertragspartner bzw
            Gast gerichtlich geltend zu machen; sonst ist das Recht erloschen.
          </p>
        </LegalParagraph>

        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>§ 12 Haftungsbeschränkungen</h3>
          <p style={{marginBottom: 10}}>
            12.1 Ist der Vertragspartner ein Konsument, wird die Haftung des
            Beherbergers für leichte Fahrlässigkeit, mit Ausnahme von
            Personenschäden, ausgeschlossen.
          </p>
          <p style={{marginBottom: 10}}>
            12.2 Ist der Vertragspartner ein Unternehmer, wird die Haftung des
            Beherbergers für leichte und grobe Fahrlässigkeit ausgeschlossen. In
            diesem Fall trägt der Vertragspartner die Beweislast für das
            Vorliegen des Verschuldens. Folgeschäden, immaterielle Schäden oder
            indirekte Schäden sowie entgangene Gewinne werden nicht ersetzt. Der
            zu ersetzende Schaden findet in jedem Fall seine Grenze in der Höhe
            des Vertrauensinteresses.
          </p>
        </LegalParagraph>

        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>§ 13 Tierhaltung</h3>
          <p style={{marginBottom: 10}}>
            13.1 Tiere dürfen nur nach vorheriger Zustimmung des Beherbergers
            und allenfalls gegen eine besondere Vergütung in den
            Beherbergungsbetrieb gebracht werden.
          </p>
          <p style={{marginBottom: 10}}>
            13.2 Der Vertragspartner, der ein Tier mitnimmt, ist verpflichtet,
            dieses Tier während seines Aufenthaltes ordnungsgemäß zu verwahren
            bzw zu beaufsichtigen oder dieses auf seine Kosten durch geeignete
            Dritte verwahren bzw beaufsichtigen zu lassen.
          </p>
          <p style={{marginBottom: 10}}>
            13.3 Der Vertragspartner bzw Gast, der ein Tier mitnimmt, hat über
            eine entsprechende Tier-Haftpflichtversicherung bzw eine
            Privat-Haftpflichtversicherung, die auch mögliche durch Tiere
            verursachte Schäden deckt, zu verfügen. Der Nachweis der
            entsprechenden Versicherung ist über Aufforderung des Beherbergers
            zu erbringen.
          </p>
          <p style={{marginBottom: 10}}>
            13.4 Der Vertragspartner bzw sein Versicherer haften dem Beherberger
            gegenüber zur ungeteilten Hand für den Schaden, den mitgebrachte
            Tiere anrichten. Der Schaden umfasst insbesondere auch jene
            Ersatzleistungen des Beherbergers, die der Beherberger gegenüber
            Dritten zu erbringen hat.
          </p>
          <p style={{marginBottom: 10}}>
            13.5 In den Salons, Gesellschafts-, Restauranträumen und
            Wellnessbereichen dürfen sich Tiere nicht aufhalten.
          </p>
        </LegalParagraph>

        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>§ 14 Verlängerung der Beherbergung</h3>
          <p style={{marginBottom: 10}}>
            14.1 Der Vertragspartner hat keinen Anspruch darauf, dass sein
            Aufenthalt verlängert wird. Kündigt der Vertragspartner seinen
            Wunsch auf Verlängerung des Aufenthalts rechtzeitig an, so kann
            der Beherberger der Verlängerung des Beherbergungsvertrages
            zustimmen. Den Beherberger trifft dazu keine Verpflichtung.
          </p>
          <p style={{marginBottom: 10}}>
            14.2 Kann der Vertragspartner am Tag der Abreise den
            Beherbergungsbetrieb nicht verlassen, weil durch unvorhersehbare
            außergewöhnliche Umstände (zB extremer Schneefall, Hochwasser etc)
            sämtliche Abreisemöglichkeiten gesperrt oder nicht benutzbar sind,
            so wird der Beherbergungsvertrag für die Dauer der Unmöglichkeit
            der Abreise automatisch verlängert. Eine Reduktion des Entgelts
            für diese Zeit ist allenfalls nur dann möglich, wenn der
            Vertragspartner die angebotenen Leistungen des
            Beherbergungsbetriebes infolge der außergewöhnlichen Witte-
            rungsverhältnisse nicht zur Gänze nutzen kann. Der Beherberger ist
            berechtigt mindestens jenes Entgelt zu begehren, das dem gewöhnlich
            verrechneten Preis in der Nebensaison entspricht.
          </p>
        </LegalParagraph>

        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>
            § 15 Beendigung des Beherbergungsvertrages – Vorzeitige Auflösung
          </h3>
          <p style={{marginBottom: 10}}>
            15.1 Wurde der Beherbergungsvertrag auf bestimmte Zeit
            abgeschlossen, so endet er mit Zeitablauf.
          </p>
          <p style={{marginBottom: 10}}>
            15.2 Reist der Vertragspartner vorzeitig ab, so ist der Beherberger
            berechtigt, das volle vereinbarte Entgelt zu verlangen. Der
            Beherberger wird in Abzug bringen, was er sich infolge der
            Nichtinanspruchnahme seines Leistungsangebots erspart oder was er
            durch anderweitige Vermietung der bestellten Räume erhalten hat.
            Eine Ersparnis liegt nur dann vor, wenn der Beherbergungsbetrieb im
            Zeitpunkt der Nichtinanspruchnahme der vom Gast bestellten
            Räumlichkeiten vollständig ausgelastet ist und die Räumlichkeit
            auf Grund der Stornierung des Vertragspartners an weitere Gäste
            vermietet werden kann. Die Beweislast der Ersparnis trägt der
            Vertragspartner.
          </p>
          <p style={{marginBottom: 10}}>
            15.3 Durch den Tod eines Gastes endet der Vertrag mit dem
            Beherberger.
          </p>
          <p style={{marginBottom: 10}}>
            15.4 Wurde der Beherbergungsvertrag auf unbestimmte Zeit
            abgeschlossen, so können die Vertragsparteien den Vertrag, bis 10.00
            Uhr des dritten Tages vor dem beabsichtigten Vertragsende,
            auflösen.
          </p>
          <p style={{marginBottom: 10}}>
            15.5 Der Beherberger ist berechtigt, den Beherbergungsvertrag mit
            sofortiger Wirkung aus wichtigem Grund aufzulösen, insbesondere wenn
            der Vertragspartner bzw der Gast
            <br />
            a) von den Räumlichkeiten einen erheblich nachteiligen Gebrauch
            macht oder durch sein rücksichtsloses, anstößiges oder sonst grob
            ungehöriges Verhalten den übrigen Gästen, dem Eigentümer, dessen
            Leute oder den im Beherbergungsbetrieb wohnenden Dritten gegenüber
            das Zusammenwohnen verleidet oder sich gegenüber diesen Personen
            einer mit Strafe bedrohten Handlung gegen das Eigentum, die
            Sittlichkeit oder die körperliche Sicherheit schuldig macht;
            <br />
            b) von einer ansteckenden Krankheit oder eine Krankheit, die über
            die Beherbergungsdauer hinausgeht, befallen wird oder sonst
            pflegedürftig wird;
            <br />
            c) die vorgelegten Rechnungen bei Fälligkeit innerhalb einer
            zumutbar gesetz-
            <br />
            ten Frist (3 Tage) nicht bezahlt.
          </p>
          <p style={{marginBottom: 10}}>
            15.6 Wenn die Vertragserfüllung durch ein als höhere Gewalt zu
            wertendes Ereignis (zB Elementarereignisse, Streik, Aussperrung,
            behördliche Verfügungen etc) unmöglich wird, kann der Beherberger
            den Beherbergungsvertrag jederzeit ohne Einhaltung einer
            Kündigungsfrist auflösen, sofern der Vertrag nicht bereits nach dem
            Gesetz als aufgelöst gilt, oder der Beherberger von seiner
            Beherbergungspflicht befreit ist. Etwaige Ansprüche auf
            Schadenersatz etc des Vertragspartners sind ausgeschlossen.
          </p>
        </LegalParagraph>

        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>§ 16 Erkrankung oder Tod des Gastes</h3>
          <p style={{marginBottom: 10}}>
            16.1 Erkrankt ein Gast während seines Aufenthaltes im
            Beherbergungsbetrieb, so wird der Beherberger über Wunsch des Gastes
            für ärztliche Betreuung sorgen. Ist Gefahr in Verzug, wird der
            Beherberger die ärztliche Betreuung auch ohne besonderen Wunsch
            des Gastes veranlassen, dies insbesondere dann, wenn dies notwen-
            dig ist und der Gast hiezu selbst nicht in der Lage ist.
          </p>
          <p style={{marginBottom: 10}}>
            16.2 Solange der Gast nicht in der Lage ist, Entscheidungen zu
            treffen oder die Angehörigen des Gastes nicht kontaktiert werden
            können, wird der Beherberger auf Kosten des Gasten für ärztliche
            Behandlung sorgen. Der Umfang dieser Sorgemaßnahmen endet jedoch
            in dem Zeitpunkt, in dem der Gast Entscheidungen treffen kann oder
            die Angehörigen vom Krankheitsfall benachrichtigt worden sind.
          </p>
          <p style={{marginBottom: 10}}>
            16.3 Der Beherberger hat gegenüber dem Vertragspartner und dem Gast
            oder bei Todesfall gegen deren Rechtsnachfolger insbesondere für
            folgende Kosten Ersatzansprüche:
            <br />
            a) offene Arztkosten, Kosten für Krankentransport, Medikamente und
            Heilbehelfe
            <br />
            b) notwendig gewordene Raumdesinfektion,
            <br />
            c) unbrauchbar gewordene Wäsche, Bettwäsche und Betteinrichtung,
            anderen-
            <br />
            falls für die Desinfektion oder gründliche Reinigung all dieser
            Gegenstände,
            <br />
            d) Wiederherstellung von Wänden, Einrichtungsgegenständen, Teppichen
            usw, soweit diese im Zusammenhang mit der Erkrankung oder den
            Todesfall ver-
            <br />
            unreinigt oder beschädigt wurden,
            <br />
            e) Zimmermiete, soweit die Räumlichkeit vom Gast in Anspruch
            genommen
            <br />
            wurde, zuzüglich allfälliger Tage der Unverwendbarkeit der Räume
            wegen
            <br />
            Desinfektion, Räumung o. ä,
            <br />
            f) allfällige sonstige Schäden, die dem Beherberger entstehen.
          </p>
        </LegalParagraph>

        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>§ 17 Erfüllungsort, Gerichtsstand und Rechtswahl</h3>
          <p style={{marginBottom: 10}}>
            17.1 Erfüllungsort ist der Ort, an dem der Beherbergungsbetrieb
            gelegen ist.
            <br />
            17.2 Dieser Vertrag unterliegt österreichischem formellen und
            materiellen Recht unter Ausschluss der Regeln des Internationalen
            Privatrechts (insb IPRG und EVÜ) sowie UN-Kaufrecht.
          </p>
          <p style={{marginBottom: 10}}>
            17.3 Ausschließlicher Gerichtsstand ist im zweiseitigen
            Unternehmergeschäft der Sitz des Beherbergers, wobei der Beherberger
            überdies berechtigt ist, seine Rechte auch bei jedem anderem
            örtlichem und sachlich zuständigem Gericht geltend zu machen.
          </p>
          <p style={{marginBottom: 10}}>
            17.4 Wurde der Beherbergungsvertrag mit einem Vertragspartner, der
            Verbraucher ist und seinen Wohnsitz bzw gewöhnlichen Aufenthalt in
            Österreich hat, geschlossen, können Klagen gegen den Verbraucher
            ausschließlich am Wohnsitz, am gewöhnlichen Aufenthaltsort oder am
            Beschäftigungsort des Verbrauchers eingebracht werden.
          </p>
          <p style={{marginBottom: 10}}>
            17.5 Wurde der Beherbergungsvertrag mit einem Vertragspartner, der
            Verbraucher ist und seinen Wohnsitz in einem Mitgliedsstaat der
            Europäischen Union (mit Ausnahme Österreichs), Island, Norwegen
            oder der Schweiz, hat, ist das für den Wohnsitz des Verbrauchers
            für Klagen gegen den Verbraucher örtlich und sachlich zuständige
            Gericht ausschließlich zuständig.
          </p>
        </LegalParagraph>

        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>§ 18 Sonstiges</h3>
          <p style={{marginBottom: 10}}>
            18.1 Sofern die obigen Bestimmungen nichts Besonderes vorsehen,
            beginnt der Lauf einer Frist mit Zustellung des die Frist
            anordnenden Schriftstückes an die Vertragspartner, welche die
            Frist zu wahren hat. Bei Berechnung einer Frist, welche nach Tagen
            bestimmt ist, wird der Tag nicht mitgerechnet, in welchen der Zeit-
            punkt oder die Ereignung fällt, nach der sich der Anfang der Frist
            richten soll. Nach Wochen oder Monaten bestimmte Fristen beziehen
            sich auf denjenigen Tage der Woche oder des Monates, welcher durch
            seine Benennung oder Zahl dem Tage entspricht, von welchem die Frist
            zu zählen ist. Fehlt dieser Tag in dem Monat, ist der in diesem
            Monat letzte Tag maßgeblich.
          </p>
          <p style={{marginBottom: 10}}>
            18.2 Erklärungen müssen dem jeweils anderen Vertragspartner am
            letzten Tag der Frist (24 Uhr) zugegangen sein.
          </p>
          <p style={{marginBottom: 10}}>
            18.3 Der Beherberger ist berechtigt, gegen Forderung des
            Vertragspartners mit eigenen Forderungen aufzurechnen. Der
            Vertragspartner ist nicht berechtigt mit eigenen Forderungen gegen
            Forderungen des Beherbergers aufzurechnen, es sei denn, der
            Beherberger ist zahlungsunfähig oder die Forderung des
            Vertragspartners ist gerichtlich festgestellt oder vom Beherberger
            anerkannt.
          </p>
          <p style={{marginBottom: 10}}>
            18.4 Im Falle von Regelungslücken gelten die entsprechenden
            gesetzlichen Bestimmungen.
          </p>
        </LegalParagraph>
        </OuterContainer>
      <Footer displayCheckin={true} />
    </div>
  );
};

export default TermsAndConditions;
