import React, {useState} from 'react'
import HeroSection from '../components/HeroSection'
import Footer from '../components/Footer/footer.component'
// evtl noch Integrieren - Tipps & Tricks für deinen Aufenthalt in Wien
// import { ArticleSection } from '../components/ArticleSection/article-section.component'

import LanguageSwitcherSelector from '../components/language-switcher/language-switcher.component'
import Header from '../components/header/header.component'

const Home = () => {

  return (
    <>
    <Header />
    <LanguageSwitcherSelector />
    <HeroSection />
    <Footer />
    </>
  )
}

export default Home