import React, {useEffect} from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
    Header,
    LanguageSwitcherContainer,
    LanguageSwitcherText,
    HeadlineContainer,
    StrongHeadline,
    SubheadLine,
    Body, 
    SubmitButton,
    SubmitLink
  } from "../Checkin/checkin.styles";
import LanguageSwitcherSelector from '../language-switcher/language-switcher.component';
import {ContactButton, ContactButtonContainer, InformationList} from './checkin-final.styles'
import { Trans, useTranslation } from 'react-i18next'
import { auth } from '../../utils/firebase/firebase.utils';


const CheckinFinal = () => {
const currentUser = auth.currentUser

  const {t} = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    !currentUser && navigate("/login");
  }, []);

  return (
    <>
    <Header>
        <LanguageSwitcherContainer>
          <LanguageSwitcherSelector />
        </LanguageSwitcherContainer>
      </Header>
      <Body style={{display:'flex', justifyContent:'center', flexDirection: 'column'}}>
        <HeadlineContainer>
          <StrongHeadline>Check In</StrongHeadline>
          <SubheadLine style={{textDecoration: 'underline dotted', textUnderlineOffset: 5}}>{t('important_information_text')}</SubheadLine>
        </HeadlineContainer>
        <InformationList>
            <ul>
                <li style={{margin: '10px 0', lineHeight: '1.6'}}>
                <Trans 
                  
                  i18nKey="checkin_final_first_point"
                  components={{ break: <br />, bold: <b />, link: <a /> }}
                />
                </li>
                <li style={{margin: '10px 0', lineHeight: '1.6'}}>
                <Trans
                  i18nKey="checkin_final_second_point"
                  components={{ break: <br />, bold: <b /> }}
                />
                </li>
                <li style={{margin: '10px 0', lineHeight: '1.6'}}>
                <Trans
                  i18nKey="checkin_final_third_point"
                  components={{ break: <br />, bold: <b /> }}
                />
                </li>
            </ul>
        </InformationList>
        
        <ContactButtonContainer>
            <ContactButton href="tel:+436769382242"> {t('call_button_text')} </ContactButton>
            <ContactButton href="mailto:max.reitner@gmx.at"> {t('mail_button_text')} </ContactButton>
        </ContactButtonContainer>
        <InformationList style={{margin: 'auto', display: 'flex', justifyContent:'center', marginTop:'10px', textAlign: 'center'}}>
            <p style={{fontWeight:'600'}}>{t('information_understood_text')}</p>
        </InformationList>
        <SubmitButton style={{margin:'auto', marginTop:'10px'}}>
          <SubmitLink to="/">{t('finish_checkin_button')}</SubmitLink>
        </SubmitButton>
        <SubmitButton inverted onClick={() => {navigate(-1)}} style={{margin:'auto', marginTop:'10px'}}>
        {t('change_checking_data_text')}
        </SubmitButton>
    </Body>
    </>
  )
}

export default CheckinFinal