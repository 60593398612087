import { createContext, useEffect, useState } from "react";
import { getAuthUserSnapshot, signInAuthUserWithEmailAndPassword } from "../utils/firebase/firebase.utils";

export const UserContext = createContext({
  currentUser: null,
  setCurrentUser: () => null,
});

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  
    const signInUser = async (email, password) => {
        const user = await signInAuthUserWithEmailAndPassword(email, password)
        setCurrentUser({ id: user.id, ...user.data() })
    }
    const updateUserFromAuth = async () => {
      const userSnap = await getAuthUserSnapshot()
      setCurrentUser({ id: userSnap.id, ...userSnap.data()})
    }

  const value = {
    currentUser, 
    signInUser,
    updateUserFromAuth
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};
