import React, {useState} from 'react'
// import Video from '../../videos/video.mp4'
import { HeroContainer, HeroBg, VideoBg, HeroBtnWrapper, HeroContent, HeroH1, HeroP, ArrowForward, ArrowRight, Test, HeroPhoneButton } from './HeroElements'
import { Button, PhoneButton, DifferentPageButton } from '../ButtonElement'
import { useTranslation } from "react-i18next";

const HeroSection = () => {
    const [hover1, setHover1] = useState(false)
    const [hover2, setHover2] = useState(false)
    const onHover1 = () => {
        setHover1(!hover1)
    }
    

    const onHover2 = () => {
        setHover2(!hover2)
    }

    const {t} = useTranslation();

  return (
    <HeroContainer id='home'>
        <HeroBg>
            {/* <VideoBg autoPlay loop muted src={Video} type='video/mp4' /> */}
        </HeroBg>
        <HeroContent>
            <HeroH1>
                {t('homepage.hero_section.headline')}
            </HeroH1>
            <HeroP>
                {t('homepage.hero_section.subheadline')}
            </HeroP>
            <HeroBtnWrapper style={{flexDirection:'row'}}>
                <DifferentPageButton to='/login' onMouseEnter={onHover1} onMouseLeave={onHover1} primary='false' dark='true' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                {t('homepage.hero_section.button1_text')} {hover1 ? <ArrowForward /> : <ArrowRight /> }
                </DifferentPageButton>
            </HeroBtnWrapper>
        </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection