import styled from "styled-components";


export const HeaderContainer = styled.div`

    display:flex;
    align-items: center;
    height: 50px;
    right: 20px;
    bottom: 10px;
    position: absolute;
    background-color: #ffffff00;
    z-index: 10;
`

export const LogoH1 = styled.h1`
    
    font-size: 1.3rem;
    font-weight: 600;
    color: #ffffff;

`

export const LoginButtonContainer = styled.div`
    
    display: flex;
    flex-direction: row;
    height: 50%;
    

`

export const LoginButton = styled.button`

    padding: 5px;
    margin-right: 10px;
    background-color: #000;
    color: #fff;
    display: block;

`