import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer/footer.component";
import { LegalParagraph, OuterContainer } from "./legalStyles";


const Imprint = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0,0)
  },[])

  return (
    <>
      <div>
      <OuterContainer>
        <button
          style={{
            paddingInline: 20,
            paddingTop: 10,
            paddingBottom: 10,
            marginBottom: 50,
            background: "none",
            border: "1px solid black",
            fontSize: 16,
            fontWeight: "bold",
          }}
          onClick={() => navigate(-1)}
        >
          Zurück zum Checkin
        </button>
        <h1>Impressum</h1>
        <h3 style={{marginBottom: 15}}>Angaben gemäß § 5 TMG</h3>
        <p>
          Maximilian Reitner <br />
          Nordbahnstraße 20/6 <br />
          A-1020 Wien
        </p>
        {/* <p>Handelsregister: FN 201206t <br />
    Registergericht: Handelsgericht Wien
    </p>
Vertreten durch:
Roman Mayrhofer und Mag. Christian Mayrhofer */}
        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>Kontakt</h3>
          <p>
            Telefon: +43 676 938 2242
            <br />
            E-Mail: max.reitner@gmx.at <br />
            A-1020 Wien
          </p>
        </LegalParagraph>
        {/* Umsatzsteuer-ID
Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
ATU50480304

Angaben zur Berufshaftpflichtversicherung
Name und Sitz des Versicherers:
Zürich Versicherungs-Aktiengesellschaft
Lassallestrasse 7, A-1020 Wien

Geltungsraum der Versicherung:
Österreich

Redaktionell verantwortlich
Hotel Wilhelmshof GmbH
Roman Mayrhofer und Mag. Christian Mayrhofer
Kleine Stadtgutgasse 4
A-1020 Wien */}
        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>EU-Streitschlichtung</h3>
          <p>
            Die Europäische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit:
            https://ec.europa.eu/consumers/odr/. Unsere E-Mail-Adresse finden
            Sie oben im Impressum.{" "}
          </p>
        </LegalParagraph>

        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h3>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </LegalParagraph>

        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>Haftung für Inhalte</h3>
          <p>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
            auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
            §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
            verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
            überwachen oder nach Umständen zu forschen, die auf eine
            rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung
            oder Sperrung der Nutzung von Informationen nach den allgemeinen
            Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
            jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
            Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
            Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
          </p>
        </LegalParagraph>
        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>Haftung für Links</h3>
          <p>
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten
            Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
            nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.
          </p>
        </LegalParagraph>
        <LegalParagraph>
          <h3 style={{marginBottom: 15}}>Urheberrecht</h3>
          <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
            Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
            Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
            gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
            werden wir derartige Inhalte umgehend entfernen.
          </p>
        </LegalParagraph>
        </OuterContainer>
      </div>
      <Footer displayCheckin={true} />
    </>
  );
};

export default Imprint;
