import { createContext, useState } from "react";

export const FormInputContext = createContext({
  gender: "", 
  setGender: () => {},
  firstName: "", 
  setFirstName: () => {},
  lastName: "", 
  setLastName: () => {},
  birthday: "", 
  setBirthday: () => {},
  nationality: "", 
  setNationality: () => {},
  address: "", 
  setAddress: () => {},
  city: "", 
  setCity: () => {},
  plz: "", 
  setPlz: () => {},
  stateAdress: "", 
  setStateAdress: () => {},
  countryAddress: "", 
  setCountryAddress: () => {},
  arrivalDate: "", 
  setArrivalDate: () => {},
  departureDate: "", 
  setDepartureDate: () => {},
  identityDoc: null, 
  setIdentityDoc: () => {},
  identityDocUrl: "", 
  setIdentityDocUrl: () => {},
  signature: null, 
  setSignature: () => {},
  signatureUrl: "", 
  setSignatureUrl: () => {},
  additionalGuests: [], 
  setAdditionalGuests: () => {}
});

export const FormProvider = ({ children }) => {
  
  const [gender, setGender] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [birthday, setBirthday] = useState("")
  const [nationality, setNationality] = useState("")
  const [address, setAddress] = useState("")
  const [city, setCity] = useState("")
  const [plz, setPlz] = useState("")
  const [stateAdress, setStateAdress] = useState("")
  const [countryAddress, setCountryAddress] = useState("")
  const [arrivalDate, setArrivalDate] = useState("")
  const [departureDate, setDepartureDate] = useState("")
  const [identityDoc, setIdentityDoc] = useState(null)
  const [identityDocUrl, setIdentityDocUrl] = useState("")
  const [signature, setSignature] = useState(null)
  const [signatureUrl, setSignatureUrl] = useState("")

  const populateFromBookingData = (dataObject) => {
    setAddress(() => dataObject.address)
    setArrivalDate(() => dataObject.arrival)
    setBirthday(() => dataObject.birthday)
    setCity(() => dataObject.city)
    setCountryAddress(() => dataObject.country)
    setDepartureDate(() => dataObject.departure)
    setFirstName(() => dataObject.firstName)
    setGender(() => dataObject.gender)
    setIdentityDocUrl(() => dataObject.identityUrl)
    setLastName(() => dataObject.lastName)
    setNationality(() => dataObject.nationality)
    setPlz(() => dataObject.plz)
    setSignatureUrl(() => dataObject.signatureUrl)
    setStateAdress(() => dataObject.state)
  }

  const value = {
  gender, 
  setGender,
  firstName, 
  setFirstName,
  lastName, 
  setLastName,
  birthday, 
  setBirthday,
  nationality, 
  setNationality,
  address, 
  setAddress,
  city, 
  setCity,
  plz, 
  setPlz,
  stateAdress, 
  setStateAdress,
  countryAddress, 
  setCountryAddress,
  arrivalDate, 
  setArrivalDate,
  departureDate, 
  setDepartureDate,
  identityDoc, 
  setIdentityDoc,
  identityDocUrl, 
  setIdentityDocUrl,
  signature, 
  setSignature,
  signatureUrl, 
  setSignatureUrl,
  populateFromBookingData
  };

  return (
    <FormInputContext.Provider value={value}>
      {children}
    </FormInputContext.Provider>
  );
};
