import React from "react";
import { FaPhone, FaEnvelope } from "react-icons/fa";
import {
  FooterContainer,
  FooterLinkR,
  FooterLinkTitle,
  FooterLinksContainer,
  FooterLinkItems,
  FooterLinksWrapper,
  FooterWrap,
  WebsiteRights,
  SocialIconLink,
  SocialIcons,
  SocialLogo,
  SocialMedia,
  SocialMediaWrap,
} from "./footer.styles";
import { animateScroll as scroll } from "react-scroll";
import { useNavigate } from "react-router-dom";

const Footer = ({ displayCheckin }) => {
  const navigate = useNavigate();
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrap>
        {displayCheckin && (
          <button
            style={{
              paddingInline: 20,
              paddingTop: 10,
              paddingBottom: 10,
              marginBottom: 50,
              background: "none",
              border: "1px solid black",
              fontSize: 16,
              fontWeight: "bold",
            }}
            onClick={() => navigate("/login")}
          >
            Checkin
          </button>
        )}

        <SocialMedia>
          <SocialMediaWrap>
            <WebsiteRights>
              Your Vienna Homes © {new Date().getFullYear()}
            </WebsiteRights>
            <FooterLinksContainer>
              <FooterLinksWrapper>
                <FooterLinkItems>
                  <FooterLinkR to="/agb">AGBs</FooterLinkR>
                  <FooterLinkR to="/imprint">Impressum</FooterLinkR>
                </FooterLinkItems>
              </FooterLinksWrapper>
            </FooterLinksContainer>
            <SocialIcons>
              <SocialIconLink
                href="mailto:yourviennahomes@gmail.com"
                target="_blank"
                aria-label="Mail"
              >
                <FaEnvelope />
              </SocialIconLink>

              <SocialIconLink
                href="tel:+436769382242"
                target="_blank"
                aria-label="Phone"
              >
                <FaPhone />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
