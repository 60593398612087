import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Form, Route, Routes } from "react-router-dom";
import Home from "./pages";
import Imprint from "./pages/imprint";
import TermsAndConditions from "./pages/termsandconditions";
import AdminLogin from "./pages/admin-login";
import CreateBookingPage from "./pages/admin-create";
import AdminDashboard from "./pages/admin-dashboard";
import LoginGuest from "./components/login-guest/login-guest.component";
import CheckinFinal from "./components/CheckinFinal/checkin-final.component";
import CheckIn from "./components/Checkin/checkin.component";

import { AuthProvider } from "./contexts/user.context";
import { FormProvider } from "./contexts/formInput.context";

function App() {
  return (
    <AuthProvider>
      <FormProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="admin" element={<AdminLogin />} />
          <Route path="/admin/create-booking" element={<CreateBookingPage />} />
          <Route path="admin/dashboard" element={<AdminDashboard />} />
          <Route path="login" element={<LoginGuest />} />
          <Route path="checkin" element={<CheckIn />} />
          <Route path="checkin-final" element={<CheckinFinal />} />
          <Route path="imprint" element={<Imprint />} />
          <Route path="agb" element={<TermsAndConditions />} />
        </Routes>
      </Router>
      </FormProvider>
    </AuthProvider>
  );
}

export default App;
