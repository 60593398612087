import styled from "styled-components";
import { Link } from "react-router-dom";

export const ContactButton = styled.a`

    border:1px solid #000; 
    color:#000;
    background-color:#fff;
    width: 80px; 
    height: 20px;
    cursor:pointer;
    text-decoration: none;
    font-size: 11pt;
    font-weight: 800;
    padding: 10px;
    font-weight: 600;
    align-items: center;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
        font-size: 10pt;
    }

`

export const ContactButtonContainer = styled.div`

    width: 50%;
    margin: 30px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    @media (max-width: 768px) {
        width: 70%;
    }
`

export const InformationList = styled.div`
    
    width:45%;
    margin: auto;
    
    font-size: 11pt;
    @media (max-width: 768px) {
        width: 70%;
        font-size: 10pt;
    }

`