import styled from "styled-components";
import {Link as LinkR} from 'react-router-dom'
import {COLORS} from "../../colors"

export const FooterContainer = styled.footer`

    background-color: ${COLORS.tertiary};

`

export const FooterWrap = styled.div`

    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;

`

export const FooterLinksContainer = styled.div`

    display: flex;
    justify-content: center;
    flex-direction: row;

    @media screen and (max-width: 820px) {
        padding-bottom: 10px;
    }

`

export const FooterLinksWrapper = styled.div`

    display: flex;
    flex-direction: row;

`

export const FooterLinkItems = styled.div`

    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 16px;
    justify-content: space-between;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    color: ${COLORS.darkText};;

    @media screen and (max-width: 420px) {
        margin: 0;
        padding: 20px;
        width: 100%;
    }
    @media screen and (max-width: 820px) {
        flex-direction: column;

    }

`

export const FooterLinkTitle = styled.h1`

    font-size: 14px;
    margin-right: 16px;

`

export const FooterLinkR = styled(LinkR)`

    color: ${COLORS.darkText};
    text-decoration: none;
    font-size: 0.9rem;

    &:hover {
        color: ${COLORS.lightText};
        transition: 0.3s ease-out
    }

`

export const SocialMedia = styled.section`

    max-width: 1000px;
    width: 100%;

`

export const SocialMediaWrap = styled.div`

    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: 40px auto;

    @media screen and (max-width: 820px) {
        flex-direction: column;
    }

`

export const SocialLogo = styled(LinkR)`

    color: ${COLORS.darkText};;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-weight: bold;

`

export const WebsiteRights = styled.small`

    color:${COLORS.darkText};;


`

export const SocialIcons = styled.div`

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 151px;

`

export const SocialIconLink = styled.a`

    color:${COLORS.darkText};;
    font-size: 24px;

`