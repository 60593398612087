import {
  FlagElement,
  LanguageDropDown,
  LanguageList,
  LanguageListElement,
  LanguageSwitcherContainer
} from "./language-switcher.styles";
import {useTranslation} from "react-i18next"

const languages = [
  { code: "en"},
  { code: "de"},
];

const LanguageSwitcherSelector = () => {
  const { i18n } = useTranslation();
  let activeLanguage = i18n.language

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <LanguageSwitcherContainer>

      <h6 style={{ fontSize:'0.8rem', marginRight: '10px', color: '#283618'}}>Language:</h6>
      <FlagElement lang={activeLanguage} />

      <LanguageDropDown>
        {languages.map((language) => {
          if (language.code != activeLanguage) {
            return (
              <LanguageList key={language.code} onClick={() => {
                changeLanguageHandler(language.code)
                }}>
                <LanguageListElement
                key={language.code}
                  value={language.code}
                  lang={language.code}
                />
              </LanguageList>
            );
          }
        })}
      </LanguageDropDown>
    </LanguageSwitcherContainer>
  );
};

export default LanguageSwitcherSelector;
