import React, { Fragment, useEffect, useState, useRef, useContext, useLayoutEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Header,
  LanguageSwitcherContainer,
  LanguageSwitcherText,
  HeadlineContainer,
  StrongHeadline,
  SubheadLine,
  CheckinForm,
  Body,
  SubmitButton,
  SubmitLink,
} from "../Checkin/checkin.styles";

// import { useSelector } from "react-redux";
// import { selectCurrentUser } from "../store/user/user.selector";
import GuestLoginForm from "../LoginFormGuest/loginform-guest.component";
import { useTranslation } from "react-i18next";
import LanguageSwitcherSelector from "../language-switcher/language-switcher.component";
import { UserContext } from "../../contexts/user.context";
import { auth } from "../../utils/firebase/firebase.utils";

const LoginGuest = () => {
    
    const {currentUser, updateUserFromAuth} = useContext(UserContext)

    const navigate = useNavigate();
    useLayoutEffect(() => {
      if(!currentUser)  {
        updateUserFromAuth() 
      }
    }, [])
    useEffect(()=> {
        currentUser && navigate('/checkin')
    }, [currentUser])

    const {t} = useTranslation()

  return (
    <Fragment>
      <Header>
        <LanguageSwitcherContainer>
          <LanguageSwitcherSelector />
        </LanguageSwitcherContainer>
      </Header>
      <Body>
        <HeadlineContainer>
          <StrongHeadline>Check In</StrongHeadline>
          <SubheadLine>
            {t('checkin_login_subheadline1')} <br /> <br /> {t('checkin_login_subheadline2')} 
          </SubheadLine>
        </HeadlineContainer>
        <GuestLoginForm />
        <SubmitButton inverted="true" style={{ display: 'flex', margin:'auto' }}>
        <SubmitLink style={{ display: 'flex', margin:'auto', fontWeight: "normal"}} inverted="true" to="/">
          {t('back_to_home')}
        </SubmitLink>
      </SubmitButton>
        {/* <StyleKreis1 />
        <StyleKreis2 />
        <StyleKreis3 /> */}
      </Body>
    </Fragment>
  );
};

export default LoginGuest;

