import styled from "styled-components";
import { Link } from "react-router-dom";
import {CountryDropdown, RegionDropdown} from 'react-country-region-selector'
import {COLORS} from "../../colors"

const size = {
    mobile: '480px',
    tablet: '768px',
    laptopS: '1024px',
    laptop: '1440px',
  }

export const device = {
    mobile: `(max-width: ${size.mobile})`,
    tablet: `(max-width: ${size.tablet})`,
    laptopS: `(max-width: ${size.laptopS})`,
    laptop: `(max-width: ${size.laptop})`
  };



export const Header = styled.div`

    display: flex;
    width: 100%;
    justify-content: space-between;

`

export const Body = styled.div`

    max-width: 1000px;
    margin: auto;

`

export const LanguageSwitcherContainer = styled.div`

    display: flex;
    justify-content: center;
    margin: 20px;
    text-align: center;

`

export const LanguageSwitcherText = styled.h2`

    font-size: 1rem;
    font-weight: 1000;

`

export const HeadlineContainer = styled.div`

    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    row-gap: 1rem;
    margin: 2rem 1rem;

`

export const StrongHeadline = styled.h1`
    font-size: 2rem;
    font-weight: 2000;
    @media ${device.tablet} {
    font-size: 1.5rem;
  };
`

export const SubheadLine = styled.h3`

    font-size: 1rem;
    font-weight: 400;
    @media ${device.tablet} {
    font-size: 0.8rem;
  };

`

export const CheckinForm = styled.form`

    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;
    margin-top: 2rem;

`

export const MainGuestFormContainer = styled.div`

    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    
`

export const GenderButtonWrap = styled.div`

    display: flex;
    flex: 0 0 100%;
    width: 100%;
    align-items: center;

`

export const RadioButtonDescription = styled.label`
 
    margin-right: 1.7rem;
    font-weight: 600;
    font-size: 11pt;
    @media ${device.laptopS} {
    font-size: 9pt;
    margin-right: 1rem;
  };

`;

export const RadioButtonLabel = styled.label`
 
  margin-left: 0.5rem;
  margin-right: 1rem;
  
  @media ${device.laptopS} {
    font-size: 0.8rem;
  };
  
`;

export const GenderRadioButton = styled.input`

  cursor: pointer;
  width: 1rem;
  height: 1rem;
  
`

export const InputWrapper = styled.div`

    display: flex;
    flex-direction: row;
    padding: 0.8rem;
    border: 1px solid #000;
    height: 1rem;
    margin-top: 1.5rem;
    margin-left: 0;
    @media (min-width: 887px) {
        width: 400px;
    }
    @media (min-width: 768px) {
        width: 340px
    }
    @media ${device.tablet} {
    margin-left: 0;
    width: 100%;
  };
  

`
export const InputLabel = styled.label`

display: flex;
    font-size: 10pt;
    font-weight: 600;
    color: #000;
    min-width: ${props => (props.wide ? `60%` : `90px`)};
    border: solid #000;
    border-width: 0 1.5px 0 0 ;
    @media ${device.laptopS} {
        min-width: ${props => (props.wide ? `50%` : `80px`)};;
        padding-top: 1px;
        font-size: 8pt;
        width: 75px;
    }

`

export const TextInput = styled.input`

    outline: none;
    border: none;
    display: block;
    font-size: 11pt;
    width: 100%;
    margin-left: 15px;
    @media ${device.laptopS} {
    font-size: 9pt;
    }

`

export const DatePicker = styled.input`

    outline: none;
    border: none;
    background-color: #fff;
    justify-content: start;
    color: black;
    text-align: left;
    font-size: 11pt;
    width: 100%;
    
    margin-left: 15px;
    @media ${device.laptopS} {
    font-size: 9pt;
    }    

`
export const SideGuestFormContainer = styled.div`

    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    margin-bottom: 1.5rem;
    padding: 0;
    
`

export const FileInputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0.8rem;
    border: ${props => props.filehere ? ('1px solid #21ad14') : ('1px solid #e60202')};
    margin-top: 1.5rem;
    margin-left: ${props => props.filehere ? ('250px') : ('0px')};
    margin-bottom: 2rem;
    
    @media ${device.laptopS} {
    margin-left: 0;
  };

  @media (min-width: 887px) {
        width: 400px;
    }
    @media (min-width: 768px) {
        width: 340px
    }

    @media ${device.tablet} {
    width: 100%;
    margin-left: 0;
  };

`

export const FileImageWrapper = styled.div`

    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
    align-items: center;

    @media ${device.laptopS} {
    margin-left: 0;
  };
    @media ${device.tablet} {
    margin-left: 0;
  };

`

export const FileInput = styled.input`

    display:none;

`

export const FilePickerButton = styled.button`

    background-color: black;
    color: #fff;
    justify-content: center;
    align-self: center;
    border: none;
    display: flex;
    font-size: 10pt;
    width: 50%;
    cursor:pointer;
    margin-left: 15px;
    @media ${device.laptopS} {
    font-size: 8pt;
    }

`

export const SignatureHolder = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border: ${props => props.filehere ? ('1px solid #21ad14') : ('1px solid #e60202')};
    padding:0;

`
export const SignatureButtons = styled.div`
    
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding:0;

`

export const SignatureButton = styled.button`

    font-weight: 600;
    width : 50%;
    background-color: #fff;
    border: solid grey;

`

export const SubmitButton = styled.button`

    text-decoration: none;
    margin: 30px;
    font-weight: 600;
    width : 200px;
    height: 50px;
    font-size: 1rem;
    background-color: ${props => props.inverted ? `${COLORS.secondary}` : `${COLORS.tertiary}`};
    border: ${props => props.inverted ? `2px solid ${COLORS.tertiary}` : `2px solid ${COLORS.tertiary}`};
`

export const SubmitLink = styled(Link)`

    text-decoration: none;
    font-size: 11pt;
    font-weight: 800;
    color: ${COLORS.darkText};
`

export const CountrySelector = styled(CountryDropdown)`


    outline: none;
    border: none;
    background-color: #fff;
    justify-content: start;
    color: black;
    text-align: left;
    font-size: 11pt;
    width: 100%;
    height: 100%;
    line-height: 100%;
    margin-left: 9px;
    
    @media (min-width: 1024px) {
      height: 18px;
      margin-top: -2px;
      margin-left: 11px;
    } 
    @media ${device.laptopS} {
    font-size: 9pt;
    }    

`

export const RegionSelector = styled(RegionDropdown)`

    outline: none;
    border: none;
    background-color: #fff;
    justify-content: start;
    color: black;
    text-align: left;
    font-size: 11pt;
    width: 100%;
    height: 100%;
    line-height: 100%;
    margin-left: 9px;
    
    @media (min-width: 1024px) {
      height: 18px;
      margin-top: -2px;
      margin-left: 11px;
    } 
    @media ${device.laptopS} {
    font-size: 9pt;
    }    

`