import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  writeBatch,
  getDocs,
  updateDoc
} from 'firebase/firestore';

import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "your-vienna-homes.firebaseapp.com",
  projectId: "your-vienna-homes",
  storageBucket: "your-vienna-homes.appspot.com",
  messagingSenderId: "1045533261045",
  appId: "1:1045533261045:web:84be2f133c62adc3f159bc"
};

const firebaseApp = initializeApp(firebaseConfig);
const secondaryApp = initializeApp(firebaseConfig, "Secondary");
const secondaryAuth = getAuth(secondaryApp);
export const storage = getStorage(firebaseApp);

export const auth = getAuth(firebaseApp);
auth.setPersistence(browserSessionPersistence)

export const db = getFirestore();

export const uploadBookingToFirestore = async (bookingNr, dataToUpload) => {
  let response = ""
  try {
    await updateDoc(doc(db, "bookings", bookingNr), dataToUpload).catch((e) => console.log(e))
    response = 200
  } catch (error){
    response = error
  }
  return response
}

export const signInAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  await signInWithEmailAndPassword(auth, email, password);

  const snapshot = await getAuthUserSnapshot()
    .catch((err) => console.log(err))

  return snapshot
};

export const getAuthUserSnapshot = async() => {
  const docRef = doc(db, "users", auth.currentUser.uid);
    console.log(auth.currentUser)

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap
    } else {
      // doc.data() will be undefined in this case
      return false
    }
}

export const signOutUser = async () => await signOut(auth);

export const getBookingData = async (currentUserBRNr) => {
    const docRef = doc(db, "bookings", currentUserBRNr);
    const docSnap = await getDoc(docRef).catch(e => console.log(e));
  
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      return false
    }
  };

export const getAllBookings = async () => {
  const colRef = collection(db, "bookings");
  const snapshot = await getDocs(colRef)
  return snapshot.docs.map(doc => ({ bookingId: doc.id, ...doc.data()}));
}

export const createNewUser = async (email, password, bookingReference, apartmentId, revenue) => {
  
  try {
  const {user} = await createUserWithEmailAndPassword(secondaryAuth, email, password)

  const userDocRef = doc(db, 'users', user.uid);
  
  const createdAt = new Date();
    
  await setDoc(userDocRef, {
        BRNr: bookingReference,
        createdAt: createdAt,
        apartmentId: apartmentId
      });
    } catch (error) {
      console.log('error creating the user', error.message);
    }

  await setDoc(doc(db, "bookings", bookingReference), {
    createdAt: new Date(),
    revenue: revenue, 
    ortstaxe: (Math.round((revenue*0.027691 + Number.EPSILON) * 100) / 100),
    apartmentId: apartmentId
  });

  secondaryAuth.signOut();
}

export const addRevenue = async (bookingReference, revenue) => {
  let result = 0
  try {
  const bookingDocRef = doc(db, 'bookings', bookingReference);
  await updateDoc(bookingDocRef, {revenue: revenue, ortstaxe: (Math.round((revenue*0.027691 + Number.EPSILON) * 100) / 100)})

  result = 200
  } catch (error) {
    console.log('error updating doc', error.message);
    result = 400
  }
  return result
}

export const getApartmentList = async () => {
  const snapshot = await getDocs(collection(db, "apartments"))
  return snapshot.docs.map(doc => ({ apartmentId: doc.id, ...doc.data()}));
}