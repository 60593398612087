import React, { useEffect, useState, useRef, createRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Header,
  HeadlineContainer,
  StrongHeadline,
  SubheadLine,
  CheckinForm,
  MainGuestFormContainer,
  SideGuestFormContainer,
  GenderButtonWrap,
  RadioButtonLabel,
  GenderRadioButton,
  RadioButtonDescription,
  Body,
  InputWrapper,
  InputLabel,
  TextInput,
  DatePicker,
  FileInputWrapper,
  FileImageWrapper,
  FileInput,
  SignatureHolder,
  SignatureButtons,
  SignatureButton,
  SubmitButton,
  FilePickerButton,
  CountrySelector,
  RegionSelector
} from "./checkin.styles";
import SignatureCanvas from "react-signature-canvas";

import heic2any from "heic2any";

import { getBookingData, storage } from "../../utils/firebase/firebase.utils";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import { Timestamp } from "firebase/firestore";
import { uploadBookingToFirestore } from "../../utils/firebase/firebase.utils";


import {useTranslation} from 'react-i18next'
import LanguageSwitcherSelector from "../language-switcher/language-switcher.component";
import { auth } from "../../utils/firebase/firebase.utils";
import { FormInputContext } from "../../contexts/formInput.context";
import { UserContext } from "../../contexts/user.context";

const CheckIn = () => {
  const {currentUser} = useContext(UserContext)
  const {
    gender, 
  setGender,
  firstName, 
  setFirstName,
  lastName, 
  setLastName,
  birthday, 
  setBirthday,
  nationality, 
  setNationality,
  address, 
  setAddress,
  city, 
  setCity,
  plz, 
  setPlz,
  stateAdress, 
  setStateAdress,
  countryAddress, 
  setCountryAddress,
  arrivalDate, 
  setArrivalDate,
  departureDate, 
  setDepartureDate,
  setIdentityDoc,
  identityDocUrl, 
  setIdentityDocUrl,
  signatureUrl, 
  setSignatureUrl,
  populateFromBookingData
  } = useContext(FormInputContext)
 
  const navigate = useNavigate();
  const {t, i18n} = useTranslation();

  const [preview, setPreview] = useState();

  const [additionalUsers, setAdditionalUsers] = useState([]);

  useEffect(() => {
    
    !currentUser && navigate("/login");
    const gBD = async () => {
      const bookingData = await getBookingData(currentUser.BRNr)
      if(bookingData) {

        populateFromBookingData(bookingData)
        setAdditionalUsers(bookingData.additionalGuests)
      } 
    }
    gBD()
    
  }, []);

  const sigPad = useRef({});
  const imageUploader = useRef(null);

  const checkinDataForUpload = {
    additionalGuests: additionalUsers,
    address: address,
    arrival: arrivalDate,
    birthday: birthday,
    city: city,
    departure: departureDate,
    firstName: firstName,
    gender: gender,
    identityUrl: identityDocUrl,
    lastName: lastName,
    nationality: nationality,
    plz: plz,
    signatureUrl: signatureUrl,
    state: stateAdress,
    country: countryAddress,
    userId: currentUser ? currentUser.id : ""
  };

  const uploader = async (e) => {
    e.preventDefault()
    let additionalMissing = false
    additionalUsers.map((guest) => {
      Object.values(guest).map((value) => {
        if (!value) additionalMissing = true 
      })
    }) 
   
    if(additionalMissing) {
      alert(t('checkin_additional_guest_missing'))
      return
    }

    if (!Object.values(checkinDataForUpload).every(Boolean)) {

      let missingData = []

      let result = Object.keys(checkinDataForUpload).filter((x) => { 

        if(typeof checkinDataForUpload[x] == "undefined") {
          return x
        }
        return checkinDataForUpload[x].length < 1
    });

      result.map((e)=> {
        switch (e) {
          case 'address':
            missingData.push(t('address'))
            break;
          case 'arrival':
             missingData.push(t('arrival'))
            break;
          case 'birthday': 
          missingData.push(t('birthday'))
          break;
          case 'city':
          missingData.push(t('city'))
            break;
          case 'departure': 
          missingData.push(t('departure'))
            break;
          case 'firstName': 
          missingData.push(t('first_name'))
            break;
          case 'gender':
          missingData.push(t('gender'))
            break;
          case 'identityUrl':
            missingData.push(t('identity_check'))
            break;
          case 'lastName': 
          missingData.push(t('last_name'))
            break;
          case 'nationality': 
          missingData.push(t('nationality'))
            break;
          case 'plz': 
          missingData.push(t('zip'))
            break;
          case 'signatureUrl': 
          missingData.push(t('signature'))
            break;
          case 'state': 
          missingData.push(t('state'))
            break;
          case 'country': 
          missingData.push(t('country'))
            break;
          default:
            break;
        }
      })

      alert(`${t('missing_doc_text') + missingData.join(', ')}`)
      return;
    }


  const res = await uploadBookingToFirestore(currentUser.BRNr, checkinDataForUpload)
    
  if(res === 200) {
    navigate("/checkin-final")
  } else {
    alert(`Error uploading File, please try again. Error: ${res}`)
  }

  };

  const addUser = (e) => {
    e.preventDefault();
    const newUserArray = [...additionalUsers];
    const newUser = {
      firstName: "",
      lastName: "",
      birthday: "",
    };
    newUserArray.push(newUser);
    setAdditionalUsers(newUserArray);
  };

  const updateAddUsersArray = (event, attribute, index) => {
    const toUpdateArray = [...additionalUsers];
    let currentData = toUpdateArray[index];
    currentData[attribute] = event.target.value;
    toUpdateArray.splice(index, 1, currentData);
    setAdditionalUsers(toUpdateArray);
  };

  const removeUser = (e) => {
    e.preventDefault();
    const newUserArray = [...additionalUsers];
    newUserArray.pop();
    setAdditionalUsers(newUserArray);
  };

  const clearSigPad = (e) => {
    e.preventDefault();
    if (signatureUrl) {
      let signatureRef = ref(
        storage,
        `signatures/${currentUser.BRNr + "_signature"}`
      );
      deleteObject(signatureRef).then(setSignatureUrl(""));
    } else {
      sigPad.current.clear();
    }
  };

  const saveSigPad = (e) => {
    e.preventDefault();
    const dataURL = sigPad.current.toDataURL("image/png");
    const data = atob(dataURL.substring("data:image/png;base64,".length)),
      asArray = new Uint8Array(data.length);
    for (var i = 0, len = data.length; i < len; ++i) {
      asArray[i] = data.charCodeAt(i);
    }

    const blob = new Blob([asArray], { type: "image/png" });

    const signatureRef = ref(
      storage,
      `signatures/${currentUser.BRNr + "_signature"}`
    );

    uploadBytes(signatureRef, blob).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setSignatureUrl(url);
      });
    });
  };

  const _validFileExtensions = [".jpg", ".jpeg", ".png", ".heic"];
  const validateImage = (img) => {
    const sFileName = img.name;
    if (sFileName.length > 0) {
      var blnValid = false;
      for (var j = 0; j < _validFileExtensions.length; j++) {
        var sCurExtension = _validFileExtensions[j];
        if (
          sFileName
            .substr(
              sFileName.length - sCurExtension.length,
              sCurExtension.length
            )
            .toLowerCase() == sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }
      if (!blnValid) {
        alert(
          "Sorry, " +
            sFileName +
            " hat das falsche Format, das Dokument kann in folgenden Formaten sein:" +
            _validFileExtensions.join(", ")
        );
        return false;
      }
    }
    return true;
  };

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!identityDocUrl) {
      setIdentityDoc(undefined);
      return;
    }

    setPreview(identityDocUrl);
  }, [identityDocUrl]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setIdentityDocUrl("");
      return;
    }

    let imageToUpload = e.target.files[0];
    if (!validateImage(imageToUpload)) {
        setIdentityDocUrl("");
      return;
    }
    const identityRef = ref(
      storage,
      `identities/${currentUser.BRNr + "_identity"}`
    );
    console.log(imageToUpload.name)
    if (imageToUpload.name.split(".").pop().toLowerCase() === "heic") {
      console.log("HEIC")
      const fr = new FileReader();
      fr.readAsArrayBuffer(imageToUpload);
      fr.onload = () => {
        const blob = new Blob([fr.result]);
        const heic2anyprops = {
          blob,
          toType: "image/jpeg",
          quality: 0.1, // cuts the quality and size by half
        };

        heic2any(heic2anyprops)
          .then((conversionResult) => {
            let jpegFile = new File([conversionResult], "Download.jpeg", {
              type: "image/jpeg",
            });
            uploadBytes(identityRef, jpegFile).then((snapshot) => {
              getDownloadURL(snapshot.ref).then((url) => {
                setIdentityDocUrl(url);
              });
            }).catch(e => alert(`Da hat etwas nicht geklappt. Bitte versuch es nochmal! Fehler: ${e}`));
          })
          .catch((e) => console.log(e));
      };
      return;
    }

    uploadBytes(identityRef, imageToUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setIdentityDocUrl(url);
      });
    });

    e.target.value = null;
  };

  const handleRadioButtonChange = (event) => {
    const value = event.target.value;
    setGender(value);
  };

  const getDate = (dep) => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dep) {
      dd += 1;
    }
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    const formattedToday = yyyy + "-" + mm + "-" + dd;
    return formattedToday;
  };

  const submitImage = (e) => {
    e.preventDefault();
    imageUploader.current.click();
  };

  return (
    <>
      <Header>

          <LanguageSwitcherSelector />
        {/* <button
          style={{
            width: "25px",
            height: "25px",
            margin: "20px",
            backgroundColor: "transparent",
            border: "0",
            cursor: "pointer",
          }}
        >
          <BurgerIcon />
        </button> */}
      </Header>
      <Body>
        <HeadlineContainer>
          <StrongHeadline>{t('checkin_headline')}</StrongHeadline>
          <SubheadLine>{t('checkin_subheadline')}</SubheadLine>
        </HeadlineContainer>
        <CheckinForm style={{marginInline: 20}}>
          <MainGuestFormContainer>
            <GenderButtonWrap>
              <RadioButtonDescription>{t('gender')}</RadioButtonDescription>
              <GenderRadioButton
                type="radio"
                name="gender"
                value="m"
                onChange={(event) => handleRadioButtonChange(event)}
              />
              <RadioButtonLabel>M</RadioButtonLabel>
              <GenderRadioButton
                type="radio"
                name="gender"
                value="f"
                onChange={(event) => handleRadioButtonChange(event)}
              />
              <RadioButtonLabel>F</RadioButtonLabel>
            </GenderButtonWrap>
            <InputWrapper>
              <InputLabel htmlFor="firstName">{t('first_name')}</InputLabel>
              <TextInput
                type="text"
                name="firstName"
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              ></TextInput>
            </InputWrapper>
            <InputWrapper>
              <InputLabel htmlFor="lastName">{t('last_name')}</InputLabel>
              <TextInput
                type="text"
                name="lastName"
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              ></TextInput>
            </InputWrapper>
            <InputWrapper>
              <InputLabel htmlFor="birthday">{t('birthday')}</InputLabel>
              <DatePicker
                type="date"
                name="birthday"
                value={birthday}
                required
                onChange={(e) => setBirthday(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <InputLabel htmlFor="nationality">{t('nationality')}</InputLabel>
              <CountrySelector
              name="nationality"
              value={nationality}
              required
              onChange={(country) =>
                setNationality(country)
              }
              />
            </InputWrapper>
            <InputWrapper>
              <InputLabel htmlFor="address">{t('address')}</InputLabel>
              <TextInput
                type="text"
                name="address"
                required
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              ></TextInput>
            </InputWrapper>
            <InputWrapper>
              <InputLabel htmlFor="city">{t('city')}</InputLabel>
              <TextInput
                type="text"
                name="city"
                required
                value={city}
                onChange={(e) => setCity(e.target.value)}
              ></TextInput>
            </InputWrapper>
            <InputWrapper>
              <InputLabel htmlFor="plz">{t('zip')}</InputLabel>
              <TextInput
                type="text"
                name="plz"
                required
                value={plz}
                onChange={(e) => setPlz(e.target.value)}
              ></TextInput>
            </InputWrapper>
            <InputWrapper>
              <InputLabel htmlFor="countryAddress">{t('country')}</InputLabel>
              <CountrySelector
              name="countryAddress"
              value={countryAddress}
              required
              onChange={(country) =>
                setCountryAddress(country)
              }
              />
            </InputWrapper>
            <InputWrapper>
              <InputLabel htmlFor="state">{t('state')}</InputLabel>
              <RegionSelector
              country={countryAddress}
              name="state"
              value={stateAdress}
              required
              onChange={(region) =>
                setStateAdress(region)
              }
              />
            </InputWrapper>
            <InputWrapper>
              <InputLabel htmlFor="arrival">{t('arrival')}</InputLabel>
              <DatePicker
                type="date"
                name="arrival"
                value={arrivalDate}
                required
                min={`${getDate()}`}
                onChange={(e) =>
                  setArrivalDate(e.target.value)
                }
              />
            </InputWrapper>
            <InputWrapper>
              <InputLabel htmlFor="departure">{t('departure')}</InputLabel>
              <DatePicker
                type="date"
                name="departure"
                value={departureDate}
                required
                min={`${getDate(true)}`}
                onChange={(e) =>
                  setDepartureDate(e.target.value)
                }
              />
            </InputWrapper>
            <FileInputWrapper filehere={identityDocUrl ? true : false}>
              <InputLabel wide htmlFor="identity">
              {t('identity_check')}
              </InputLabel>
              <FileImageWrapper>
                <FileInput
                  wide
                  type="file"
                  ref={imageUploader}
                  id="selectedFile"
                  identity
                  name="identity"
                  onChange={onSelectFile}
                />

                <FilePickerButton onClick={(e) => submitImage(e)}>
                {t('upload_button')}
                </FilePickerButton>
                {identityDocUrl && (
                  <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <img
                      src={preview}
                      style={{
                        maxWidth: "100%",
                        paddingLeft: "10px",
                        margin: "20px 0",
                      }}
                    />
                    <FilePickerButton
                      onClick={() => {
                        const identityRef = ref(
                          storage,
                          `identities/${currentUser.BRNr + "_identity"}`
                        );
                        deleteObject(identityRef).then(
                          setIdentityDocUrl("")
                        );
                      }}
                    >
                      delete
                    </FilePickerButton>
                  </div>
                )}
              </FileImageWrapper>
            </FileInputWrapper>
          </MainGuestFormContainer>

          {additionalUsers.length === 0 ? (
            <SignatureButton
              style={{
                border: "1px solid #000",
                color: "#000",
                backgroundColor: "#fff",
                width: "200px",
                height: "30px",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={addUser}
            >
              {t('add_guest_button')}
            </SignatureButton>
          ) : null}
          <hr
            style={{
              background: "#000",
              color: "#000",
              borderColor: "#000",
              height: "0.001rem",
              width: "100%",
            }}
          />

          {additionalUsers.map((user, i) => {
            return (
              <>
                <SideGuestFormContainer>
                  <InputWrapper>
                    <InputLabel htmlFor="firstName">First Name </InputLabel>
                    <TextInput
                      type="text"
                      name="firstName"
                      onChange={(e) => {
                        updateAddUsersArray(e, "firstName", i);
                      }}
                    ></TextInput>
                  </InputWrapper>
                  <InputWrapper>
                    <InputLabel htmlFor="lastName">Last Name</InputLabel>
                    <TextInput
                      type="text"
                      name="lastName"
                      onChange={(e) => {
                        updateAddUsersArray(e, "lastName", i);
                      }}
                    ></TextInput>
                  </InputWrapper>
                  <InputWrapper>
                    <InputLabel htmlFor="birthday">Birthday</InputLabel>
                    <DatePicker
                      type="date"
                      name="birthday"
                      onChange={(e) => {
                        updateAddUsersArray(e, "birthday", i);
                      }}
                    />
                  </InputWrapper>
                </SideGuestFormContainer>
                {i > additionalUsers.length - 2 ? (
                  <SignatureButton
                    style={{
                      border: "1px solid #000",
                      color: "#000",
                      backgroundColor: "#fff",
                      width: "200px",
                      height: "30px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={addUser}
                  >
                    {t('add_guest_button')}
                  </SignatureButton>
                ) : null}
                {i > additionalUsers.length - 2 ? (
                  <SignatureButton
                    id={i}
                    style={{
                      border: "0",
                      color: "#794141",
                      backgroundColor: "#fff",
                      width: "200px",
                      height: "30px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={removeUser}
                  >
                    Delete guest
                  </SignatureButton>
                ) : null}
                <hr
                  style={{
                    background: "#000",
                    color: "#000",
                    borderColor: "#000",
                    height: "0.001rem",
                    width: "100%",
                  }}
                />
              </>
            );
          })}
          <SignatureHolder filehere={signatureUrl ? true : false}>
            <h4
              style={{ fontSize: "10pt", margin: "10px", position: "absolute" }}
            >
              {t('signature')}
            </h4>
            {signatureUrl ? (
              <img src={signatureUrl} />
            ) : (
              <SignatureCanvas
                ref={sigPad}
                penColor="red"
                style={{}}
                canvasProps={{
                  width: 300,
                  height: 150,
                  border: "1px",
                  className: "sigCanvas",
                }}
              />
            )}
            <SignatureButtons>
              <SignatureButton
                style={{ borderWidth: "1.5px 1.5px 0 0" }}
                onClick={clearSigPad}
              >
                {t('reset')}
              </SignatureButton>
              {!signatureUrl && (
                <SignatureButton
                  style={{ borderWidth: "1.5px 0 0 0" }}
                  onClick={saveSigPad}
                >
                  {t('save')}
                </SignatureButton>
              )}
            </SignatureButtons>
          </SignatureHolder>
          <SubmitButton
            type="submit"
            onClick={(e)=> uploader(e)}
          >
            {t('finish_checkin_button')}
          </SubmitButton>
        </CheckinForm>
      </Body>
    </>
  );
};

export default CheckIn;
