import { HeaderContainer, LoginButtonContainer, LogoH1, LoginButton } from "./header.styles"
import {Link} from 'react-router-dom'


const Header = () => {

    return (
        <HeaderContainer>

        </HeaderContainer>
    )

}


export default Header